.simple-layout {
    .site-layout {
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        flex-direction: row;

        .site-layout-container {
            min-height: 380px;
            min-width: 350px;
            width:450px;
            background: none;
            border: none;
            .logo {
                span {
                    font-size: 40px;
                    margin-right: 30px;
                }
                font-size: 1.75rem;
                margin: 30px 0px;
            }

            .ant-form-item {
                margin-bottom: 24px;
            }
        }

        @media only screen and (max-width: 375px) {
            & {
                position: relative;
            }

            .site-layout-container {
                width: 100%;
                min-height: auto;
                min-width: auto;
                position: absolute;
                height: 100%;
                border: none;
            }
        }
    }

}

@primary-color: #1076d4;@link-color: #1076d4;@warning-color: #efa615;@error-color: #db1c26;@font-size-base: 16px;@border-radius-base: 4px;