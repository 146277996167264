.login-page {
    &.simple-layout {
        .flex-col {
            display: flex;
            flex-direction: column;
        }
        .btn-forgot-password {
            margin-top: 1rem;
            text-align: right;
        }
    }
}

@primary-color: #1076d4;@link-color: #1076d4;@warning-color: #efa615;@error-color: #db1c26;@font-size-base: 16px;@border-radius-base: 4px;