.summary-item {
    display: flex;
    justify-items: flex-start;
    vertical-align: middle;

    .summary-item-label {
        flex: 1 1;
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
        font-weight: 500;
    }

}

.list-action-btn-delete {
    padding: 0;
}

@media only screen and (max-width: 320px) {
    .shopping-cart-drawer .ant-drawer-content-wrapper {
        width: 100%;
    }
}

@media only screen and (min-width: 480px) {
    .shopping-cart-drawer .ant-drawer-content-wrapper {
        width: 380px;
    }
}

@primary-color: #1076d4;@link-color: #1076d4;@warning-color: #efa615;@error-color: #db1c26;@font-size-base: 16px;@border-radius-base: 4px;