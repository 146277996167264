#sidebar-layout,
.ant-pro-drawer-sider {
    .ant-pro-sider-logo {
        &.ant-pro-sider-collapsed {
            .uv-icon-unique-tour-logo {
                font-size: 14px;
            }
            h1 {
                display: none;
            }
        }
        .uv-icon-unique-tour-logo {
            font-size: 30px;
            color: #fff;
        }

        h1 {
            margin: 10px 0 0 0;
        }
    }
}

.ant-pro-global-header.ant-pro-global-header-layout-side
    .sidebar-layout-header {
    .ant-pro-global-header-logo {
        .uv-icon-unique-tour-logo {
            font-size: 30px;
            color: #000;
        }
    }
    h1 {
        display: none;
    }
}

@primary-color: #1076d4;@link-color: #1076d4;@warning-color: #efa615;@error-color: #db1c26;@font-size-base: 16px;@border-radius-base: 4px;