@font-face {
  font-family: 'univtour';
  src: url('/styles/univtour/fonts/univtour.eot?t7cb3f');
  src: url('/styles/univtour/fonts/univtour.eot?t7cb3f#iefix') format('embedded-opentype'), url('/styles/univtour/fonts/univtour.ttf?t7cb3f') format('truetype'), url('/styles/univtour/fonts/univtour.woff?t7cb3f') format('woff'), url('/styles/univtour/fonts/univtour.svg?t7cb3f#univtour') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="uv-icon-"],
[class*=" uv-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'univtour' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uv-icon-unique-tour-logo:before {
  content: "\e900";
}
