.bookings-layout {
    .site-layout {
        padding: 0 50px;
        margin-top: 64px;

        @media (max-width: 768px ) {
            padding: 0px;
        }
    }
}

@primary-color: #1076d4;@link-color: #1076d4;@warning-color: #efa615;@error-color: #db1c26;@font-size-base: 16px;@border-radius-base: 4px;