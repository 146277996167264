.bookings-layout {
    .header {
        position: fixed;
        z-index: 1;
        width: 100%;
        background: #fff;
        -webkit-box-shadow: 0 2px 8px #f0f1f2;
        box-shadow: 0 2px 8px #f0f1f2;
        display: flex;
        .menu-row {
            background: none;
            border: 0;
        }

        .btn-shopping-cart .anticon-shopping {
            font-size: 1.5rem;
        }
        .btn-side-menu-trigger{
            display: none;
            align-self: center;
            position: fixed;
            left: 0;
        }

        .side-btn-shopping-cart{
            position: fixed;
            right: 10px;
            align-self: center;
            display:none;
        }
    }

    .side-menu{
        position: fixed;
        z-index: 1;
        width: 100%;
        top: 64px;
        height: 100vh;
        &.ant-menu-inline-collapsed:not(.ant-layout-sider-children > ul) {
            width: 0px;
        }
    }

    .logo {
        display: inline;
        
        height: 100%;

        img {
            width: 100px;
        }
    }

    .ant-anchor-wrapper,
    .ant-anchor {
        display: flex;
    }

    @media (max-width: 768px ) {
        .header{
                .btn-side-menu-trigger, .side-btn-shopping-cart{
                display: inline-block;
            }
        }

        .menu-row, .header .btn-shopping-cart {
            display: none;
        }

        .logo{
            
            flex:1;
            text-align: center;
        }
    }
}
@primary-color: #1076d4;@link-color: #1076d4;@warning-color: #efa615;@error-color: #db1c26;@font-size-base: 16px;@border-radius-base: 4px;