.bookings-list-container{
    >.ant-card{
        background:none;
    }
    .booking-summary-card{
        margin-bottom: 24px;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
@primary-color: #1076d4;@link-color: #1076d4;@warning-color: #efa615;@error-color: #db1c26;@font-size-base: 16px;@border-radius-base: 4px;