.category-item-card {
    &.ant-card {
        min-height: 200px;
        display: flex;

        .ant-card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
    }

    .category-item-selected {
        .ant-space {
            width: 100%;
        }
    }
}

@primary-color: #1076d4;@link-color: #1076d4;@warning-color: #efa615;@error-color: #db1c26;@font-size-base: 16px;@border-radius-base: 4px;